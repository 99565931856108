import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import FormRedirectionWrapper from 'components/FormRedirectionWrapper';
import Layout from 'components/Layout';
import AdditionalDriversForm from 'forms/AdditionalDriversForm';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import { navigatePreservingQuote } from 'helpers/navigation';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { useCoverDetails } from 'state/formData/coverDetails';
import { CsHero, CsMeta } from 'types/contentStack';

const STEP = 4;

type AdditionalDriversProps = {
  data: {
    csAdditionalDrivers: {
      meta: CsMeta;
      hero: CsHero;
      next_button_text: string;
      info_banner: {
        heading: string;
        body: string;
      };
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csAdditionalDrivers {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      next_button_text
      info_banner {
        heading
        body
      }
    }
  }
`;

const AdditionalDrivers: React.FC<AdditionalDriversProps> = ({
  data: {
    csAdditionalDrivers: {
      meta,
      hero: { heading, subheading },
      next_button_text,
      info_banner,
    },
  },
  location,
}) => {
  const [coverDetails] = useCoverDetails();
  usePageTracking(meta.meta_title, !!coverDetails.paymentOption);

  useEffect(() => trackCheckoutEvent(STEP, null, 'Quote and Buy'), []);

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <FormRedirectionWrapper location={location}>
        <Layout meta={meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
          <HeroPlain heading={heading} subhead={subheading || undefined} />
          <AdditionalDriversForm
            infoBanner={info_banner}
            moveBack={() => navigatePreservingQuote(quoteAndBuyRoutes.aboutYourCover)}
            moveNextText={next_button_text}
            moveNext={() => navigatePreservingQuote(quoteAndBuyRoutes.quoteLoading)}
          />
        </Layout>
      </FormRedirectionWrapper>
    </LoadQuoteInProgressWrapper>
  );
};

export default AdditionalDrivers;
